var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"650"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(
        _vm.$store.getters['auth/isLoggedIn'] &&
        _vm.$route.name === 'appointmentView' &&
        _vm.edit !== false
      )?_c('v-btn',_vm._g({staticStyle:{"position":"absolute","right":"95px"},attrs:{"icon":"","small":""}},on),[_c('v-icon',{attrs:{"color":"primary","large":""}},[_vm._v("mdi-plus-circle ")])],1):_vm._e()]}}])},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$tc("customer", 2))+" ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"py-0"},[_c('v-row',[_c('v-spacer'),_c('AddExtraCustomer',{attrs:{"appointment":_vm.appointment},on:{"update":function($event){return _vm.$emit('update')}}})],1)],1),_c('v-data-table',{attrs:{"items":_vm.extra_customers,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.phone",fn:function(ref){
      var item = ref.item;
return [(item.user.phone == null)?_c('span',[_vm._v("-")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.user.phone))])]}},{key:"item.last_name",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.last_name || "-")+" ")]}},{key:"item.email",fn:function(ref){
      var item = ref.item;
return [(item.user.email)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('a',_vm._g({domProps:{"textContent":_vm._s(item.user.email)},on:{"click":function($event){return _vm.$router.push({
                  name: 'email',
                  params: { email: item.user.email },
                })}}},on))]}}],null,true)},[_vm._v(" "+_vm._s(item.user.email)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [(_vm.$store.getters['auth/isLoggedIn'] && _vm.edit !== false)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$router.push({ name: 'customerEdit', params: { id: item.id } })}}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" $edit ")])],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }